<template>
  <div class="auth-layout row align-content--center">
    <div class="flex xs12 pa-3 flex-center">
      <router-link class="flex-center" to="/">
        <img src="/img/logo.png" width="250" />
      </router-link>
    </div>

    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-inner-loading
            :size="40"
            color="primary"
            :loading="loading"
            icon="sports_soccer"
          >
            <va-card-content>
              <h2 style="color: #fff">{{ $t("auth.login") }}</h2>

              <va-separator />
              <div class="pa-3">
                <form @submit.prevent="login">
                  <va-input
                    class="mb-4"
                    v-model="username"
                    type="text"
                    :label="$t('auth.email|phone')"
                    :error="!!userErrors.length"
                    :error-messages="userErrors"
                  />
                  <va-input
                    class="mb-4"
                    v-model="password"
                    type="password"
                    :label="$t('auth.password')"
                    :error="!!passwordErrors.length"
                    :error-messages="passwordErrors"
                  />

                  <div
                    class="auth-layout__options d-flex align--center justify--start"
                  >
                    <router-link
                      class="ml-1 link text-light"
                      :to="{ name: 'recover-password' }"
                    >
                      <va-button flat style="color: #fff">
                        {{ $t("auth.recover_password") }}
                      </va-button>
                    </router-link>
                  </div>

                  <div class="d-flex justify--center mt-3">
                    <va-button flat color="#fff" type="submit" class="my-0">{{
                      $t("auth.login")
                    }}</va-button>
                  </div>
                </form>
              </div>
            </va-card-content>
          </va-inner-loading>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { gql, GraphQLClient } from "graphql-request";
import hash from "@/store/hash";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      keepLoggedIn: false,
      userErrors: [],
      passwordErrors: [],
      loading: false,
    };
  },
  computed: {
    formReady() {
      return !this.userErrors.length && !this.passwordErrors.length;
    },
  },
  methods: {
    async login() {
      this.userErrors = this.username ? [] : [this.$t("errors.required")];
      this.passwordErrors = this.password ? [] : [this.$t("errors.required")];
      if (!this.formReady) {
        return;
      }
      this.loading = true;

      const loginMutation = gql`
        mutation login($username: String, $password: String) {
          login(loginInput: { user: $username, password: $password }) {
            id
            token
            status
          }
        }
      `;
      const client = new GraphQLClient(this.$store.state.appUrl, {
        headers: {
          authorization: "Bearer MY_TOKEN",
        },
      });
      let variables = {
        username: this.username,
        password: this.password,
      };

      try {
        const response = await client.request(loginMutation, variables);
        localStorage["token"] = response.login.token;
        if (response.login.status) {
          variables = { id: response.login.id };
          const getUserData = gql`
            query ($id: Int) {
              Users(id: $id) {
                id
                email
                name
                user_role {
                  type
                }
              }
            }
          `;
          const userData = await client.request(getUserData, {
            id: response.login.id,
          });
          let role = userData.Users[0].user_role.type;
          if (role == "super_admin" || role == "admin" || role == "editor") {
            const credit = {
              username: this.username,
              password: hash.encrypt(this.password),
            };
            localStorage["login"] = JSON.stringify(credit);
            localStorage["userData"] = JSON.stringify(userData.Users[0]);
            this.$store.dispatch("storeUserDataAfterLogin", userData.Users[0]);
            this.$router.push({ name: "dashboard" });
            this.loading = false;
          } else {
            this.loading = false;
            this.$swal.fire(" Not authorized !! ");
          }
        } else {
          this.loading = false;
          this.$swal.fire({
            icon: "error",
            text: "Wrong credentials",
            confirmButtonColor: "#023b81",
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$swal.fire({
          icon: "info",
          title: "Oops... , Something went wrong !",
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
        });
      }
    },
  },
  async mounted() {
    if (localStorage["userData"]) {
      const loginMutation = gql`
        mutation login($username: String, $password: String) {
          login(loginInput: { user: $username, password: $password }) {
            id
            token
            status
          }
        }
      `;
      const client = new GraphQLClient(this.$store.state.appUrl, {});
      let loginData = JSON.parse(localStorage["login"]);

      let variables = {
        username: loginData.username,
        password: hash.decrypt(loginData.password),
      };

      try {
        const response = await client.request(loginMutation, variables);
        localStorage["token"] = response.login.token;
        if (response.login.status) {
          variables = { id: response.login.id };
          const getUserData = gql`
            query ($id: Int) {
              Users(id: $id) {
                id
                email
                name
                user_role {
                  type
                }
              }
            }
          `;
          const userData = await client.request(getUserData, {
            id: response.login.id,
          });
          const role = userData.Users[0].user_role.type;
          if (role == "super_admin" || role == "admin" || role == "editor") {
            localStorage["userData"] = JSON.stringify(userData.Users[0]);
            this.$store.dispatch("storeUserDataAfterLogin", userData.Users[0]);
            this.$router.push({ name: "dashboard" });
          }
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$swal
          .fire({
            icon: "info",
            title: "Oops... , Something went wrong !",
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if ((result.value = true)) {
              location.reload();
            }
          });
      }
    } else {
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
.va-checkbox__label {
  color: white !important;
}
.auth-layout {
  min-height: 100vh;
  background: linear-gradient(
    267deg,
    rgba(1, 55, 127, 1) 15%,
    rgba(15, 106, 156, 1) 71%
  );
  background-size: cover;
  &__card {
    width: 100%;
    max-width: 600px;
    background: rgb(255 255 255 / 9%) !important;
    box-shadow: 0 8px 32px 0 rgb(6 75 138) !important;
    backdrop-filter: blur(7px) !important;
    border-radius: 10px !important;
  }
}
.va-input_solid .va-input__container {
  background: rgb(255 255 255 / 17%) !important;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%) !important;
  backdrop-filter: blur(12px) !important;
  border-radius: 10px !important;
  border: 1px solid rgb(255 255 255 / 9%) !important;
  margin-bottom: 5px !important;
}

.va-input__label {
  color: white !important;
}
</style>
